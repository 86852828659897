import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../vectorsurv_icon.svg";

function NavigationBar(args) {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * hamburger helper
   */
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar {...args} bg='dark' variant={"dark"} expand={"sm"} dark={true}>
        <NavbarBrand href='/' tag={Link} to='/'>
          <img
            alt='VectorSurv logo'
            src={logo}
            style={{
              height: 20,
              width: 20,
              marginBottom: 7,
            }}
          />{" "}
          <span style={{ fontWeight: "bold", fontSize: "large" }}>
            VectorSurv Open Data Portal
          </span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className='me-auto' navbar>
            <NavItem>
              <NavLink tag={Link} href='/' to='/'>
                Request Data
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} href='/agencies/' to='/agencies/'>
                Participating Agencies
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href='https://vectorsurv.org/'
                target='_blank'
                rel='noopener noreferrer'
              >
                About VectorSurv
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} href='/information/' to='/information/'>
                FAQ
              </NavLink>
            </NavItem>
          </Nav>
          {/*<NavbarText>Simple Text</NavbarText>*/}
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
