import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "react-dropdown-tree-select/dist/styles.css";
import "./App.css";
import NavigationBar from "./components/Navbar";

import Cookies from "universal-cookie";
import axios from "axios";

// CODE SPLIT
const Public = React.lazy(() => import("./components/Public/"));
const Agencies = React.lazy(() => import("./components/Agencies/"));
const InfoPage = React.lazy(() => import("./components/FAQ"));

const messageStyles = {
  display: "flex",
  fontSize: 24,
  justifyContent: "center",
  margin: "200px auto",
  textAlign: "center",
  width: 600,
};

const cookies = new Cookies();

const PrivateRoute = ({
  component: Component,
  authenticated,
  ...restOfProps
}) => {
  return (
    <Route
      render={(props) =>
        authenticated === true ? (
          <Component {...props} {...restOfProps} />
        ) : (
          <Restricted />
        )
      }
    />
  );
};

// A component to render out if a route is protected
const Restricted = () => {
  return (
    <div style={messageStyles}>
      <div>
        This page is currently restricted to gateway users only. Log in to your
        account at{" "}
        <a
          href='https://gateway.vectorsurv.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          gateway.vectorsurv.org
        </a>{" "}
        and then come back here and refresh the page! <br />
      </div>
    </div>
  );
};

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      authtoken: null,
      username: null,
    };
  }

  componentDidMount = async () => {
    let { authtoken, username } = cookies.getAll();
    if (process.env.NODE_ENV === "development") {
      this.authenticated = true;
      this.forceUpdate();
    } else {
      const authCheck = await axios
        .get(`https://mathew.vectorsurv.org/v2/auth/${username}/${authtoken}`)
        .then((res) => res.status)
        .catch((err) => err);
      if (authCheck === 200) {
        console.log("authcheck is " + authCheck);
        this.authenticated = true;
        this.forceUpdate();
      } else {
        console.log("authCheck is " + authCheck);
        this.authenticated = false;
      }
    }
  };

  render() {
    const commonProps = {};

    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className='container' style={{ maxWidth: "100%", padding: "0px" }}>
          <NavigationBar />
          <React.Fragment>
            <Suspense
              fallback={
                <div style={messageStyles}>Loading open data portal...</div>
              }
            >
              <Switch>
                <PrivateRoute
                  path='/request'
                  authenticated={this.authenticated}
                  component={Public}
                  {...commonProps}
                />
                <Route
                  path='/agencies'
                  render={(props) => <Agencies {...props} {...commonProps} />}
                />
                <Route
                  path='/information'
                  render={(props) => <InfoPage {...props} {...commonProps} />}
                />
                <Redirect from='/' to='/request' />
              </Switch>
            </Suspense>
          </React.Fragment>
        </div>
      </Router>
    );
  }
}

export default App;
